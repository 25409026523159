$(document).ready(function () {


    linkDiv();

    $(".block.downloads .expandableContent, .block.products .expandableContent").each(function(){
      var element = $(this);
      var elementPreset = $(element).find('ul').length;
      if(elementPreset != 0){
        element.find('ul').after('<span class="btn"><a href="#" class="showListItems">Toon alles</a></span>')
      };
    });

    function expandableContent(elemClass) {
      var maxItems = 5;
      var elems = [].slice.call(document.querySelectorAll(elemClass));
      elems.forEach(function(elem) {
        if ([].slice.call(elem.querySelectorAll('li')).length > maxItems) {
          elem.classList.add('show-button');
          var btn = elem.querySelector('.showListItems');
          btn.addEventListener('click', function(e) {
            e.preventDefault();
            elem.classList.toggle('expanded');
            btn.innerText = btn.innerText === 'Toon alles' ? 'Toon minder' : 'Toon alles';
          });
        }
        else{
          elem.classList.add('hide-button');
        }
      });
    };
    expandableContent('.expandableContent');

    var btn = $('#top');

    $(window).scroll(function() {
        if ($(window).scrollTop() > 300) {
            btn.addClass('show');
        } else {
            btn.removeClass('show');
        }
    });

    btn.on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, '300');
    });

    // if no image exist, push 'kruimelpad' down to create space between logo and list items
    if ($('#CCIPlaceHolder_Before_Content .fullWidth img').length == 0){
        $('#kruimelpad').css("padding-top", "25px");
    }

    if ($(".image-background-src").length != '0') {
        $(".image-background").each(function () {
            if ($(this).find(".image-background-src img").length != '0') {
                var background = $(this).find(".image-background-src img").attr('src');
                $(this).css('backgroundImage', "url(" + background + ")")
                $(this).css('backgroundRepeat', "no-repeat")
                $(this).css('backgroundSize', "cover")
            }
            else {
                $(this).css('backgroundColor', "#ffffff")
            }
        });
    };

    /*Member search*/
    if ($("#ohnl_ledenzoek_wrapper").length > -1) {
        $("#ohnl_ledenzoek_wrapper .zoekterm input[type=text]").attr('placeholder', 'Plaatsnaam of bedrijfsnaam');
        $("#ohnl_ledenzoek_wrapper .submit a").click(function(e) {          
          var inputValue = $.trim($("#ohnl_ledenzoek_wrapper input[id*='ohnl_ledenzoek_tbzoekterm']").val());
          $("#ohnl_ledenzoek_wrapper input[id*='ohnl_ledenzoek_tbzoekterm']").val(inputValue);

          var clickAction = $("#ohnl_ledenzoek_wrapper a[id*='ohnl_ledenzoek_lbzoeken']").attr('href');
          var nrOfNumbers = inputValue.replace(/[^0-9]/g,"").length;
          var includesZip = false;
          if (nrOfNumbers === 4) {
            includesZip = true;
          }

          if (includesZip) {
            if (inputValue.match(/^\d/) && inputValue.length == 6) { // Most likely zip without space
              inputValue = inputValue.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'') // // add spaces after 4 digits, remove white spaces
            }

            if (inputValue.match(/^\d/) && inputValue.length > 7) { // Most likely zip with a company name
              inputValue = inputValue.substring(0, 4); // Remove all but the zip code
            }

            $("#ohnl_ledenzoek_wrapper input[id*='ohnl_ledenzoek_tbzoekterm']").val(inputValue);
          }

          // String starts numeric, we want a minimum of 4 characters
          if (inputValue.match(/^\d/) && inputValue.length < 4) {
            alert("Vul minimaal 4 karakters in.");
            e.preventDefault(); // Do not execute the search
          } else if (inputValue.length < 3){
            alert("Vul minimaal 3 karakters in.");
            e.preventDefault(); // Do not execute the search
          } else {
            eval(clickAction);
          }
        });
    };

    moveBreadCrumbs();

    //Wrapping bedrijfsaanpasser attributes.
    var specialisaties = document.querySelector('#modifycompanyattributeinfo_form_container');

    if(specialisaties !== null){
        var attributes = [].slice.call(document.querySelectorAll('.modifycompanyattributeinfo_input_label_checkbox, .modifycompanyattributeinfo_input_label_textbox, .modifycompanyattributeinfo_input_label_checkboxlist, .modifycompanyattributeinfo_input_label_textbox'));
        var wrapNames = ["vacancy-element"];

        //Remove prefixes and add class to wrap sections
        attributes.forEach(function(attribute){                
            var text = attribute.innerHTML;
            console.log(text)
            if (text.includes("VACATURES:")){
                attribute.parentElement.classList.add(wrapNames[0])
                splittedText = text.split(':')[1]
                attribute.innerHTML = splittedText; 
            }   
            if (text.includes("VACATURES: Categorie")){
                attribute.parentElement.classList.add(wrapNames[0])
                splittedText = text.split(':')[1]
                attribute.innerHTML = splittedText; 
                attribute.parentElement.classList.add('checkboxlist')
            }   
            if (text.includes("Attribute_title_Vacatures")){
                attribute.parentElement.classList.add(wrapNames[0])
                splittedText = text.split('Attribute_title_')[1]
                attribute.innerHTML = splittedText; 
            }   
            else{
                splittedText = text.split(':')[1]
                attribute.innerHTML = splittedText;  
            }                           
        })
        wrapElementsDiv ();
    };

    function wrapElementsDiv (){
        $( ".vacancy-element" ).wrapAll( "<div id='vacancys' class='section-wrapper' />");             
    }

    //Add jumpable anchor to custom title attribute element.
    var attributeTitles = [].slice.call(document.querySelectorAll("div[id*='Attribute_title']"));
    //console.log(attributeTitles)

  if(attributeTitles.length !== 0){
      attributeTitles.forEach(function(attributeTitle){  
        var title = attributeTitle.innerText;
        var anchor = document.createElement("a");
        //console.log(title)
        anchor.setAttribute("href", "#")
        anchor.setAttribute("id", title)
        anchor.setAttribute("class", "attribute-title")
        anchor.innerText = title;    
        attributeTitle.innerText ="";            
        attributeTitle.appendChild(anchor);
      })

    var pageUrl = window.location.href;
    if(pageUrl.includes('#')){
        document.querySelector("#" + pageUrl.split("#")[1]).scrollIntoView({
        behavior: 'smooth'
        });
    }
  }

  var logOutButton = $("#Block_Header_Uitloggen_ctl00");
  if(logOutButton.length >= 1){
    logOutButton.on("click", function(){                               
        sessionStorage.removeItem('userLoggedIn');
    });
  }


});

$(document).foundation({
    equalizer: {
        // Specify if Equalizer should make elements equal height once they become stacked.
        equalize_on_stack: true
    }
});

$(document).ajaxStop(function () {

    if(document.getElementById('lbl_usernameretrievepassword')){
      document.getElementById('lbl_usernameretrievepassword').innerText = 'Vul uw gebruikersnaam of e-mailadres in. U ontvangt dan een e-mail met instructies.';
    }
 
    
    linkDiv();

    /*Front edn profile mutations*/
      $(".add_mutation_link, .mutation_link").wrap("<span class='btn'></span>");
      var profileName = $("#profileLink").text();
      $("#Block_Header_Uitloggen_ctl00").attr('title', 'U bent ingelogd als' + profileName + '. Klik hier om uit te loggen.');

      /*Change labels profile*/
      $(".mutation_link a").text("Gegevens bewerken");
      $(".CrmSiteProfileMutationsEditFunctionRenderControl_vrijveld1 span").text("Lidnummer");
      $(".CrmSiteProfileMutationsEditFunctionRenderControl_functieclassificatie.element_container span").text("Functie");
      $(".CrmSiteProfileMutationsEditFunctionRenderControl_doorkiesnummer.element_container span").text("Doorkiesnummer");
      $(".CrmSiteProfileMutationsEditFunctionRenderControl_ingangsdatum.element_container .label_container span").text("Ingangsdatum");

      if($(".meeting-time").length > 0){
        $(".agenda-slider .my-meetings.content").each(function(){
          var meetingStartTime = $(this).find('.meeting-starttime').text();
          var meetingEndTime = $(this).find('.meeting-endtime').text();
          var startRes = meetingStartTime.substring(0,6);
          var endRes = meetingEndTime.substring(0,5);
          $(this).find('.meeting-starttime').text(startRes)
          $(this).find('.meeting-endtime').text(endRes)
        });
      }

      //Change label vrij veld 1
    if($(".SN_MedewerkerData_VrijVeld1_Label").length != '0'){
      $(".SN_MedewerkerData_VrijVeld1_Label").text("Lidnummer:");
      $(".SN_MedewerkerData_VrijVeld1_Label, .SN_MedewerkerData_VrijVeld1_Value").show();
    }
    
});

//General functions
// Show text if user is already logged in
$.get('/cms/asynchronousrendering/componentcmsshowloginlogout/CheckIfUserIsLoggedin.aspx', function (check) {
    if (check === 'true') {
        $("#login").hide();
        $(".loggedinUser").attr('style', 'display: inline-block');
    }
    else {
        $("#login").attr('style', 'display: inline-block');
    }
});

//system search input validation
var standardSearchValidation = function (formId, errorId) {
  var veld  = $("#" + formId).find("input:text");
  var error = $("#" + errorId);
  veld.blur();
  $(error).fadeIn();
  $(document).one('mousedown keydown scroll', function (event) {
    $(error).fadeOut();
    event.stopPropagation();
    veld.focus();
  });
};

//Move crumbpath
function moveBreadCrumbs (){
  var kruimelpad = document.getElementById('kruimelpad').outerHTML;
  var contentPageTarget = $(".row.intro-text").length; //0 or 1
  var bodyHasClassHome = $("body.home").length; //true or false

  if(bodyHasClassHome = '0'){
    if(contentPageTarget ==='1'){
      $("#CCIPlaceHolder_Content").before(kruimelpad)
      $("header #kruimelpad").remove();
    }
    else{
      $("#CCIPlaceHolder_Content").before(kruimelpad)
      $("header #kruimelpad").remove();
    }

  }
};

//Make whole element clickable.
function linkDiv() {
    $('.linkdiv').each(function () {
        $(this).click(function (e) {
            e.preventDefault();

            // slider blocks clickable
            var slideLink = $(this).find('.title-article').find('a').attr('href');

            var link = $(this).find('.title-article').attr('href');
            var target = $(this).find('.title-article').attr('target');

            if (link) {
                if (!target || target === "_self") {
                    window.location.href = link;
                } else {
                    window.open(link);
                }
            }

            if (slideLink) {
                if (slideLink){
                    window.location.href = slideLink;
                }
            }
        });
    });

    $('.member-benefits-variant-2 .text.primary, .member-benefits .text.primary').each(function () {
        $(this).click(function (e) {
            e.preventDefault();
            var link = $(this).find('a').attr('href');
            var target = $(this).find('a').attr('target');

            if (link) {
                if (!target || target === "_self") {
                    window.location.href = link;
                } else {
                    window.open(link);
                }
            }
        });
    });
};

//Callback handlers
function contentLoaderNieuws(id, data) {
    var scopeSelector = "#" + id;
    $(scopeSelector).hide();
    $(scopeSelector).html(data);
    $(scopeSelector).show();

    $('.actueel').on('init', function (slick) {
        $(".actueel").show();
    });

    $(".actueel").not('.slick-initialized').slick({
        dots: false,
        infinite: true,
        autoplay: true,
        pauseOnHover: true,
        speed: 400,
        autoplaySpeed: 6000,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    })
};
function contentLoaderLogos(id, data) {
    var scopeSelector = "#" + id;
    $(scopeSelector).hide();
    $(scopeSelector).html(data);
    $(scopeSelector).show();

    //randomize position of logos before init slick.
    var logos = document.getElementById('partner-logos');
    for (var i = logos.children.length; i >= 0; i--) {
        logos.appendChild(logos.children[Math.random() * i | 0]);
    }

    $("#partner-logos").not('.slick-initialized').slick({
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: true,
        pauseOnHover: true,
        speed: 600,
        autoplaySpeed: 3000,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    })
};
function contentLoaderAgenda(id, data) {
    var scopeSelector = "#" + id;
    $(scopeSelector).hide();
    $(scopeSelector).html(data);
    $(scopeSelector).show();

    $(".agenda-slider").not('.slick-initialized').slick({
        dots: false,
        infinite: true,
        autoplay: true,
        pauseOnHover: true,
        speed: 400,
        autoplaySpeed: 6000,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    })
}
function contentLoaderDocs(id, data) {
  if (document.querySelector('#' + id) != null && data) {
    var elem = document.querySelector('#' + id);
    elem.innerHTML = data;
    elem.classList.add('loaded');
  }
}
function contentMenu (id, data) {
  var scopeSelector = "#" + id;
  $(scopeSelector).hide();
  $(scopeSelector).html(data);
   var kruimelPad = $("#kruimelpad");

   if(kruimelPad){
     $("#kruimelpad ul li").each(function(){
       var text = $(this).text();
       if(text === "Thema's"){
         $(this).remove();
       };
       if(text === "Actueel"){
         $(this).remove();
       };
     });
   };

  $(scopeSelector).show();
}

//Meeting copy training name to form input
(function checkForButton() {
  var parentElement = $(".training-dates-form .formV3");

  if (parentElement.length !=0) {
    var inputAvailable = setInterval(function() {
      checkBtn();
    }, 500);

    var checkBtn = function() {
      var titleInput = document.querySelector('#id_v3dtnvu');
      if (titleInput) {
        var nameTraining = document.querySelector('.training-title').innerText;
        document.getElementById("id_v3dtnvu").value = nameTraining;
        clearInterval(inputAvailable);
      }
    };
  }
})();

//Change stsMemberSearch markup to make it full width
(function fullWidthMemberSearch() {
  var searchElement = $("#STSLedenzoekForm");

  if (searchElement.length !=0) {
    document.querySelector('.GoogleMapResult_Map').classList.add('medium-12', 'large-12');
    document.querySelector('.GoogleMapResult_Map').classList.remove('large-8', 'medium-8');
    document.querySelector('.GoogleMapResult_Grid').classList.add('medium-12', 'large-12');
    document.querySelector('.GoogleMapResult_Grid').classList.remove('medium-8', 'large-8');
    document.querySelector('#filter').classList.add('medium-8', 'large-8');
    document.querySelector('#filter').classList.remove('medium-4', 'large-4');
  }
})();








